import {theme} from '@styles/theme';
import React, {ChangeEvent, FC, useEffect, useRef, useState} from 'react';
import {DefaultTheme} from 'styled-components';
import {
  ClearSearchingValueBtn,
  ErrorText,
  InsideInputLabel,
  Label,
  Option,
  SearchingInput,
  SearchingWrapper,
  SelectItem,
  SelectList,
  SelectWrapper,
  Title,
} from './Select.styles';

interface SelectProps {
  id: string;
  value: string | number;
  onChange?: (e: any) => void;
  options: Array<{title: string; value: string}>;
  label?: string;
  style?: Record<string, any>;
  testID?: string;
  error?: string;
  inputDisabled?: boolean;
  readableWhenDisabled?: boolean;
  disabled?: string;
  labelBG?: string;
  borderColor?: string;
  labelColor?: string;
  textColor?: string;
  className?: string;
  withSearching?: boolean;
  rounded?: boolean;
  withoutErrorText?: boolean;
  withInsideLabel?: boolean;
  insideLabel?: string;
  menuWidth?: string;
  wrap?: boolean;
  optimizeRendering?: boolean;
}

export interface StyledSelectProps {
  theme: DefaultTheme;
  focus?: boolean;
  error?: string | undefined;
  labelBG?: string;
  borderColor?: string;
  labelColor?: string;
  textColor?: string;
  inputDisabled?: boolean;
  readableWhenDisabled?: boolean;
  rounded?: boolean;
  withInsideLabel?: boolean;
  wrap?: boolean;
}

const Select: FC<SelectProps> = ({
  id,
  value,
  onChange,
  options,
  label,
  style,
  testID,
  error,
  inputDisabled,
  readableWhenDisabled,
  disabled,
  labelBG,
  borderColor,
  labelColor,
  textColor = `${theme.colors.darkText}`,
  className,
  withSearching,
  withInsideLabel,
  insideLabel,
  withoutErrorText,
  menuWidth = 'inherit',
  rounded,
  wrap,
  optimizeRendering,
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(value ?? options[0]?.value);
  const [checkBlur, setCheckBlur] = useState(0);
  const [searchInFocus, setSearchInFocus] = useState(false);
  const [listPosition, setListPosition] = useState<'top' | 'bottom'>('top');

  const [searchingValue, setSearchingValue] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(0);

  const selectListRef = useRef<HTMLDivElement>(null);
  const selectWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!checkBlur) {
      return;
    }
    if (checkBlur === 1) {
      setTimeout(() => setCheckBlur(2), 300);
    } else {
      if (!searchInFocus) {
        setIsOpen(false);
        setIsFocus(false);
      }
      setCheckBlur(0);
    }
  }, [checkBlur]);

  useEffect(() => {
    window.addEventListener('click', () => {
      setIsOpen(false);
      setIsFocus(false);
    });
    return () => {
      window.removeEventListener('click', () => {
        setIsOpen(false);
        setIsFocus(false);
      });
    };
  }, []);

  useEffect(() => {
    setHighlightedIndex(0);
  }, [searchingValue]);

  const scrollItemIntoView = () => {
    if (
      !selectListRef.current ||
      (selectListRef.current && !selectListRef.current?.querySelector('.active'))
    )
      return;
    const container = selectListRef.current;
    const item = selectListRef.current?.querySelector('.active');
    const containerRect = container!.getBoundingClientRect();
    const itemRect = item!.getBoundingClientRect();

    if (itemRect.bottom > containerRect.bottom) {
      container!.scrollTop += itemRect.bottom - containerRect.bottom + 225;
    } else if (itemRect.top < containerRect.top) {
      container!.scrollTop -= containerRect.top - itemRect.top + 225;
    }
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleValueChange = (value: string) => {
    setCurrentValue(value);
  };

  const handleChange = (value: string) => {
    handleValueChange(value);

    if (onChange) onChange(value);

    setIsOpen(false);
    setIsFocus(false);
  };

  const filteredPersons = options.filter(option => {
    return (
      String(option.value).toLowerCase().includes(searchingValue.toLowerCase()) ||
      String(option.title).toLowerCase().includes(searchingValue.toLowerCase())
    );
  });

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    filteredPersons: Array<{title: string; value: string}>,
  ) => {
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      setHighlightedIndex(
        highlightedIndex === 0 ? filteredPersons.length - 1 : highlightedIndex - 1,
      );
      scrollItemIntoView();
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      setHighlightedIndex(
        highlightedIndex === filteredPersons.length - 1 ? 0 : highlightedIndex + 1,
      );
      scrollItemIntoView();
    } else if (event.key === 'Enter') {
      event.preventDefault();
      handleChange(filteredPersons[highlightedIndex].value);
    }
  };

  const getCurrentSelectState = () => {
    const currentTitle = options.filter(option => option.value === currentValue);
    if (currentTitle.length === 0) {
      setCurrentValue(options[0].value);
      return options[0].title;
    } else {
      return currentTitle[0].title;
    }
  };

  function searchCurrentValue(e: ChangeEvent<HTMLInputElement>) {
    const searchQuery = e.target.value;
    setSearchingValue(searchQuery);
  }

  function adjustDropdownPosition() {
    const rect = selectWrapperRef?.current?.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect!.bottom;

    if (spaceBelow < 300) {
      setListPosition('bottom');
    } else {
      setListPosition('top');
    }
  }

  return (
    <>
      <SelectWrapper
        className={className}
        onClick={e => {
          e.stopPropagation();
          if (!inputDisabled) {
            adjustDropdownPosition();
            setIsOpen(!isOpen);
            setIsFocus(!isFocus);
          }
        }}
        focus={isFocus}
        onBlur={() => {
          setCheckBlur(1);
        }}
        error={error}
        style={style}
        labelBG={labelBG}
        borderColor={borderColor}
        labelColor={labelColor}
        inputDisabled={inputDisabled}
        data-testid={testID}
        rounded={rounded}
        id={id}
        onKeyDown={e => handleKeyDown(e, filteredPersons)}
        tabIndex={0}
        ref={selectWrapperRef}>
        <Label
          htmlFor={id}
          focus={isFocus}
          error={error}
          labelBG={labelBG}
          labelColor={labelColor}>
          {label}
        </Label>
        {withInsideLabel && (
          <InsideInputLabel htmlFor={id}>{insideLabel}</InsideInputLabel>
        )}
        {options.length !== 0 && (
          <SelectItem
            withInsideLabel={withInsideLabel}
            aria-label={label}
            onBlur={() => setIsFocus(false)}
            textColor={textColor}
            inputDisabled={inputDisabled}
            readableWhenDisabled={readableWhenDisabled}
            wrap={wrap}>
            {getCurrentSelectState()}
          </SelectItem>
        )}
        {(!optimizeRendering || isOpen) && (
          <SelectList
            isOpen={isOpen}
            id="selectList"
            ref={selectListRef}
            position={listPosition}
            menuWidth={menuWidth}>
            {withSearching && (
              <SearchingWrapper>
                <SearchingInput
                  onClick={e => e.stopPropagation()}
                  value={searchingValue}
                  onChange={searchCurrentValue}
                  placeholder="Поиск"
                  onBlur={() => setSearchInFocus(false)}
                  onFocus={() => setSearchInFocus(true)}
                />
                {searchingValue && (
                  <ClearSearchingValueBtn
                    onClick={e => {
                      e.stopPropagation();
                      setSearchingValue('');
                    }}>
                    &#215;
                  </ClearSearchingValueBtn>
                )}
              </SearchingWrapper>
            )}
            {filteredPersons.map((option, index) => {
              return (
                <Option
                  onClick={e => {
                    e.stopPropagation();
                    handleChange(option.value);
                    setHighlightedIndex(index);
                    setIsOpen(false);
                    setIsFocus(false);
                  }}
                  onMouseMove={() => {
                    setHighlightedIndex(index);
                  }}
                  className={index === highlightedIndex ? 'active' : ''}
                  highlighted={index === highlightedIndex}
                  id={option.value}
                  choosed={option.value === currentValue}
                  key={`${option.value}-${index}`}
                  disabled={option.value === disabled}>
                  <Title>{option.title}</Title>
                </Option>
              );
            })}
          </SelectList>
        )}
      </SelectWrapper>
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
};

export default Select;
