import {theme} from '@styles/theme';
import styled from 'styled-components';
import {StyledSelectProps} from './Select';

export const SelectWrapper = styled.div<StyledSelectProps>`
  min-width: 0;
  display: flex;
  width: 100%;
  cursor: ${({inputDisabled}) => (inputDisabled ? 'default' : 'pointer')};
  position: relative;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: ${({borderColor, focus, theme, error}) => {
    switch (true) {
      case borderColor && borderColor.length !== 0 && error && error!.length !== 0:
        return `1px solid ${theme.colors.darkRed}`;
      case borderColor && borderColor.length !== 0:
        return `1px solid ${borderColor}`;
      case focus:
        return `1px solid ${theme.colors.brandGreenV2}`;
      case error && error.length !== 0:
        return `1px solid ${theme.colors.darkRed}`;
      default:
        return `1px solid ${theme.colors.lightGrayV2}`;
    }
  }};
  border-radius: ${({rounded}) => (rounded ? '4px' : '0')};
  background-color: transparent;
  transition: all 0.5s;
  @media(max-width: 698px) {
  	max-width: 95vw;
  }
  & label {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 16px);
  }
  /* & label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 16px);
    font-size: 12px;
    transition: color 0.5s, border 0.5s;
    color: ${({labelColor, focus, theme, error}) => {
    switch (true) {
      case labelColor && labelColor.length !== 0 && error && error!.length !== 0:
        return `${theme.colors.darkRed}`;
      case labelColor && labelColor.length !== 0:
        return `${labelColor}`;
      case focus:
        return `${theme.colors.brandGreenV2}`;
      case error && error.length !== 0:
        return `${theme.colors.darkRed}`;
      default:
        return `${theme.colors.gray}`;
    }
  }};
    padding: 0 4px;
    background-color: ${({labelBG}) => (labelBG ? `${labelBG}` : 'white')};
    position: absolute;
    top: -7px;
    left: 7px;
  } */
`;

export const Label = styled.label<StyledSelectProps>`
  white-space: nowrap;
  width: fit-content;
  font-size: 12px;
  transition: color 0.5s, border 0.5s;
  color: ${({labelColor, focus, theme, error}) => {
    switch (true) {
      case labelColor && labelColor.length !== 0 && error && error!.length !== 0:
        return `${theme.colors.darkRed}`;
      case labelColor && labelColor.length !== 0:
        return `${labelColor}`;
      case focus:
        return `${theme.colors.brandGreenV2}`;
      case error && error.length !== 0:
        return `${theme.colors.darkRed}`;
      default:
        return `${theme.colors.gray}`;
    }
  }};
  padding: 0 4px;
  background-color: ${({labelBG}) => (labelBG ? `${labelBG}` : 'white')};
  position: absolute;
  top: -7px;
  left: 7px;
`;

export const SelectItem = styled.div<StyledSelectProps>`
  margin-left: 2px;
  background-color: transparent;
  border: none;
  letter-spacing: 0.5px;
  font-size: 18px;
  padding: 16px 10px;
  outline: none;
  width: calc(100% - 40px);
  color: ${({textColor, inputDisabled, readableWhenDisabled}) =>
    inputDisabled && !readableWhenDisabled ? theme.colors.lightGrayV2 : textColor};
  white-space: ${({wrap}) => (wrap ? 'wrap' : 'nowrap')};
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: ${({withInsideLabel}) => (withInsideLabel ? '26px' : '16px')};
  :after {
    content: url('/assets/icons/arrowDown.svg');
    position: absolute;
    right: 14px;
    width: 7px;
    margin-top: -3px;
    transform: scale(1.3);
    ${({textColor, inputDisabled}) =>
      'filter: ' +
      (inputDisabled
        ? 'brightness(0) saturate(100%) invert(84%) sepia(28%) saturate(0%) hue-rotate(201deg) brightness(99%) contrast(95%)'
        : textColor === 'white' && `invert(100) brightness(10)`)}
  }
`;

export const SelectList = styled.div<{
  isOpen: boolean;
  position: 'top' | 'bottom';
  menuWidth: string;
}>`
  position: absolute;
  ${({position}) => (position === 'top' ? 'top: 120%;' : 'bottom: 120%;')}
  left: 0;
  background-color: white;
  border: 2px solid #e6e6e6;
  height: fit-content;
  max-height: 300px;
  width: ${({menuWidth}) => menuWidth};
  outline: none;
  border-radius: 4px;
  opacity: ${({isOpen}) => (isOpen ? '1' : '0')};
  display: ${({isOpen}) => (isOpen ? '' : 'none')};
  z-index: ${({isOpen}) => (isOpen ? '999999' : '-1')};
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior: contain;
  scroll-behavior: smooth;
  overflow-block: scroll;
  &::-webkit-scrollbar {
    height: 6px;
    width: 4px;
    background: #ededed;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    width: 4px;
    background: #b1b1b1;
    border-radius: 4px;
    &:hover {
      background-color: ${theme.colors.brandGreenV2};
    }
  }
`;

export const Option = styled.div<{
  choosed: boolean;
  disabled: boolean;
  highlighted: boolean;
}>`
  position: relative;
  display: flex;
  gap: 11px;
  align-items: center;
  word-wrap: break-word;
  pointer-events: ${({disabled}) => (disabled ? 'none' : '')};
  display: ${({disabled}) => (disabled ? 'none' : '')};
  background-color: ${({highlighted}) =>
    highlighted ? `${theme.colors.brandGreenV2Aplha5}` : `${theme.colors.white}`};
  min-height: 42px;
  &:hover {
    cursor: pointer;
  }
  :before {
    position: absolute;
    left: 10px;
    top: 20%;
    content: ${({choosed}) =>
      choosed ? `url('/assets/icons/checkboxGreenIcon.svg')` : ''};
    margin-right: -10px;
  }
`;

export const Title = styled.p`
  max-width: 85%;
  color: ${theme.colors.darkText};
  margin: 10px 0px 10px 30px;
  font-size: 18px;
`;

export const SearchingInput = styled.input`
  width: 100%;
  height: 30px;
  color: ${theme.colors.darkV2};
  padding: 10px 0px 10px 30px;
  position: relative;
  display: flex;
  gap: 11px;
  align-items: center;
  font-size: 18px;
  border: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${theme.colors.blackOpacity02};
  }
`;

export const SearchingWrapper = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
  align-items: center;
  padding-right: 10px;
`;

export const ClearSearchingValueBtn = styled.button`
  display: flex;
  position: absolute;
  right: 10%;
  opacity: 0.4;
  justify-content: center;
  align-items: center;
  min-width: 14px;
  min-height: 14px;
  background-color: #d9d9d9;
  border-radius: 50%;
  margin-right: 7px;
  transition: opacity ease-in-out 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`;

export const InsideInputLabel = styled.label`
  position: absolute;
  top: 7px;
  left: 12px;
  right: 10px;
  color: ${theme.colors.brandGreenV2};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1px;
  opacity: 1;
  height: fit-content;
  transition: all 0.25s;
`;


export const ErrorText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: ${theme.colors.darkRed};
  margin-top: 12px;
  overflow-wrap: break-word;
`;